import InfiniteScroll from 'react-infinite-scroll-component';
import Checkbox from '../../../ui/formComponents2/checkbox/Checkbox';
import { MaterialIcon } from './MaterialIcon/MaterialIcon';
import LoaderTables from '../../../ui/loaders/loaderTables/LoaderTables';
import { useLCAMaterials } from '../../hooks/useLCAMaterials';
import type { IFilter } from 'utils/url';
import { LCAFormMaterial } from './SelectMaterialModal/constants';

type Props = {
  selectedMaterials: LCAFormMaterial[];
  setSelectedMaterials: React.Dispatch<React.SetStateAction<LCAFormMaterial[]>>;
  filters?: IFilter[];
};

export const MaterialTable = ({ selectedMaterials, setSelectedMaterials, filters }: Props) => {
  const { data, hasNextPage, fetchNextPage, isFetching } = useLCAMaterials({ filters });

  const onCheck = (material: LCAFormMaterial) => (selected: boolean) => {
    if (selected) {
      setSelectedMaterials((prev) => [...prev, material]);
    } else {
      setSelectedMaterials((prev) => prev.filter((m) => m.material_id !== material.material_id));
    }
  };

  const formattedData: typeof selectedMaterials =
    data?.map((material) => ({
      material_id: material.id,
      quantity: '',
      unit: '',
      unit_id: '',
      type: material.type,
      name: material.name,
      supplier: material.supplier,
      unit_list: material.unit_list,
      country: material.country,
      location: material.location,
      impact_source_id: material.impact_source_id
    })) ?? [];

  return (
    <InfiniteScroll
      dataLength={data?.length ?? 0}
      next={fetchNextPage}
      loader={null}
      hasMore={hasNextPage}
      style={{ overflow: 'hidden', paddingBottom: '1rem' }}
      scrollableTarget='modal-content'>
      <div className='flex-col gap-y-4'>
        {formattedData?.map((material) => (
          <Checkbox
            key={material.material_id}
            text={
              <div className='flex gap-x-4'>
                <MaterialIcon type={material.type} />
                <div className='flex-col gap-y-1'>
                  <span className='font-body-b2-sb text-neutral-gray-cold-20'>{material.name}</span>
                  <span className='font-body-b2-r text-neutral-gray-warm-20'>
                    {material.supplier}
                  </span>
                </div>
              </div>
            }
            onChange={onCheck(material)}
            selected={selectedMaterials.some((m) => m.material_id === material.material_id)}
          />
        ))}

        {isFetching && <LoaderTables mode='fit' />}
      </div>
    </InfiniteScroll>
  );
};
