import { useState } from 'react';
import { useLCATranslation } from '../../../../customHooks/translations/useLCATranslation';
import Button from '../../../ui/button/Button';
import Modal from '../../../ui/modal/Modal';
import { LCAMaterial } from '../../../../services/api/lca/lcaPortfolio';
import { useLCAMaterials } from '../../hooks/useLCAMaterials';
import InfiniteScroll from 'react-infinite-scroll-component';
import Checkbox from '../../../ui/formComponents2/checkbox/Checkbox';
import { MaterialIcon } from './MaterialIcon/MaterialIcon';
import { BlueTag } from '../../../ui/blueTag/BlueTag';
import WarningLabel from '../../../ui/statusLabels/warningLabel/WarningLabel';
import Icon from '../../../ui/icon/Icon';
import { IFilter } from '../../../../utils/url';
import { Filters } from '../../../layout/NewFilters/Filters';
import { FilterText } from '../../../layout/NewFilters/FilterText';
import FilterHandlers from '../../../layout/NewFilters/FiltersHandlers';
import LoaderTables from '../../../ui/loaders/loaderTables/LoaderTables';
import { FilterSection } from '../../../layout/NewFilters/FilterSection';
import { DEFAULT_HEIGHT } from '../constants';

type Props = {
  goBack: () => void;
  updateMaterials: (materials: LCAMaterial[]) => void;
  defaultMaterials: LCAMaterial[];
};

export const AddInputModal = ({ goBack, updateMaterials, defaultMaterials }: Props) => {
  const { t } = useLCATranslation();

  const [selectedMaterials, setSelectedMaterials] = useState<LCAMaterial[]>(defaultMaterials);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [open, setOpen] = useState(false);

  const { data, isFetching, fetchNextPage, hasNextPage } = useLCAMaterials({ filters });

  const onCheck = (material: LCAMaterial) => (selected: boolean) => {
    if (selected) {
      setSelectedMaterials((prev) => [...prev, material]);
    } else {
      setSelectedMaterials((prev) => prev.filter((m) => m.id !== material.id));
    }
  };

  const onSave = () => {
    updateMaterials(selectedMaterials);
    goBack();
  };

  const options = [
    { label: t('lcaInputs.types.material'), value: 'material' },
    { label: t('lcaInputs.types.energy'), value: 'energy' }
  ];

  return (
    <Filters.Root filters={filters} setFilters={setFilters} open={open} setOpen={setOpen}>
      <Filters.Menu>
        <FilterSection.Multiple
          title={t('lcaDetail.processFlow.processModal.inputModal.typeTitle')}
          field='type'
          options={options}
          type='in'
        />
      </Filters.Menu>
      <Modal.Header
        title={t('lcaDetail.processFlow.processModal.inputModal.title')}
        description={t('lcaDetail.processFlow.processModal.inputModal.description')}
      />
      <div className='flex-col gap-y-2 mb-2'>
        <BlueTag>
          <div className='flex items-center gap-x-2'>
            <Icon icon='information' color='pending' />
            <span className='m-0 font-body-b2-r'>
              {t('lcaDetail.processFlow.processModal.inputModal.warning1')}
            </span>
          </div>
        </BlueTag>
        <WarningLabel>
          <span className=' m-0 font-body-b2-r'>
            {t('lcaDetail.processFlow.processModal.inputModal.warning2')}
          </span>
        </WarningLabel>
      </div>
      <div
        style={{
          height: `calc(${DEFAULT_HEIGHT} - 0.75rem)`,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <div className='flex gap-x-4 my-4'>
          <FilterText
            field='name'
            type='il'
            placeholder={t('lcaDetail.processFlow.processModal.inputModal.materialPlaceholder')}
          />
          <FilterHandlers blacklistedFilters={{ all: ['name'] }} />
        </div>
        <Modal.Content style={{ flexGrow: 1 }}>
          <InfiniteScroll
            dataLength={data?.length ?? 0}
            next={fetchNextPage}
            loader={null}
            hasMore={hasNextPage}
            style={{ overflow: 'hidden' }}
            scrollableTarget='modal-content'>
            <div className='flex-col gap-y-4'>
              {data?.map((material) => (
                <Checkbox
                  key={material.id}
                  text={
                    <div className='flex gap-x-4'>
                      <MaterialIcon type={material.type} />
                      <div className='flex-col gap-y-1'>
                        <span className='font-body-b2-sb text-neutral-gray-cold-20'>
                          {material.name}
                        </span>
                        <span className='font-body-b2-r text-neutral-gray-warm-20'>
                          {material.supplier}
                        </span>
                      </div>
                    </div>
                  }
                  onChange={onCheck(material)}
                  selected={selectedMaterials.some((m) => m.id === material.id)}
                />
              ))}

              {isFetching && <LoaderTables mode='fit' />}
            </div>
          </InfiniteScroll>
        </Modal.Content>
      </div>
      <Modal.Buttons>
        <Button
          lookAndFeel='secondary'
          onClick={goBack}
          text={t('lcaDetail.processFlow.processModal.inputModal.cancel')}
          size='small'
        />
        <Button
          lookAndFeel='primary'
          text={t('lcaDetail.processFlow.processModal.inputModal.save')}
          size='small'
          onClick={onSave}
        />
      </Modal.Buttons>
    </Filters.Root>
  );
};
