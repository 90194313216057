import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import BusinessTravelUpload from '../../admin/businessTravelUpload/BusinessTravelUpload';
import Emails from '../../admin/emails/Emails';
import Invofox from '../../admin/invofox/Invofox';
import LCAAdmin from '../../admin/lca/LCAAdmin';
import { LCAAdminScenarios } from '../../admin/lcaScenarios/LCAScenarios';
import { LCAAdminV2 } from '../../admin/lcaV2/LCAAdmin';
import { CodeManagement } from '../../admin/purchases/codeManagement/CodeManagement';
import { PurchasesUpload } from '../../admin/purchases/purchasesUpload/PurchasesUpload';
import ShipmentsUpload from '../../admin/shipmentsUpload/ShipmentsUpload';
import TasksList from '../../admin/tasks/Tasks';
import VehicleConsumptionUpload from '../../admin/vehicleConsumptionUpload/VehicleConsumptionUpload';
import { MITECO } from '../../communicate/MITECO/MITECO';
import GlecReport from '../../communicate/glecReport/GlecReport';
import CustomizeMainPage from '../../customize/CustomizeMainPage';
import CustomEF from '../../customize/customEF/CustomEF';
import CustomEFDetails from '../../customize/customEF/customEFDetails/CustomEFDetails';
import { CustomMetrics } from '../../customize/customMetrics/CustomMetrics';
import Grouping from '../../customize/grouping/Grouping';
import GroupingDetail from '../../customize/grouping/groupingDetail/GroupingDetail';
import IntensityMetricsGroups from '../../customize/intensityMetrics/IntensityMetricGroups';
import IntensityMetricsDetail from '../../customize/intensityMetrics/intensityMetricsDetail/IntensityMetricsDetail';
import ControlPanel from '../../dashboard/controlPanel/ControlPanel';
import { Layout } from '../../dashboard/dashboardCommon/layout/Layout';
import { DashboardEsg } from '../../dashboard/dashboardEsg/DashboardEsg';
import DashboardGrouping from '../../dashboard/dashboardGrouping/DashboardGrouping';
import { DashboardInvestment } from '../../dashboard/dashboardInvestment/DashboardInvestment';
import DashboardLogistic from '../../dashboard/dashboardLogistic/DashboardLogistic';
import DashboardTotal from '../../dashboard/dashboardTotal/DashboardTotal';
import AvoidedEmissions from '../../improve/avoidedEmissions/AvoidedEmissions';
import Modeling from '../../improve/modeling/Modeling';
import { Sbti } from '../../improve/sbti/Sbti';
import { LCADetail } from '../../lca/Portfolio/LCADetail/LCADetail';
import { LCAPortfolio } from '../../lca/Portfolio/LCAPortfolio';
import EmployeeFormController from '../../measure/activity/employees/employeeForm/EmployeeFormController';
import UseAndEndOfLife from '../../measure/activity/useAndEndOfLife/UseAndEndOfLife';
import CompanyDetail from '../../measure/companies/companyDetail/CompanyDetail';
import CreateOrganizationHolding from '../../measure/companies/holding/createOrganizationHolding/CreateOrganizationHolding';
import Datadis from '../../measure/datadis/Datadis';
import DatadisContracts from '../../measure/datadis/datadisContracts/DatadisContracts';
import EsgMetrics from '../../measure/esgMetrics/EsgMetrics';
import { EsgMetricsInfography } from '../../measure/esgMetrics/EsgMetricsInfography';
import ESGMetricsDetail from '../../measure/esgMetrics/components/esgMetricsDetail/ESGMetricsDetail';
import Events from '../../measure/events/Events';
import EventDetail from '../../measure/events/eventDetail/EventDetail';
import FacilityDetail from '../../measure/facilities/facilityDetail/FacilityDetail';
import Funds from '../../measure/funds/Funds';
import Hubs from '../../measure/hubs/Hubs';
import UploadedFiles from '../../measure/uploadedFiles/UploadedFiles';
import Vehicles from '../../measure/vehicles/listVehicles/Vehicles';
import OrganizationDetail from './../../../components/admin/organizationDetail/OrganizationDetail';
import OrganizationList from './../../../components/admin/organizations/OrganizationList';
import SnippetUpload from './../../../components/admin/snippetUpload/SnippetUpload';
import UsersList from './../../../components/admin/usersList/UsersList';
import GHGReport from './../../../components/communicate/ghgReport/GHGReport';
import DcycleReport from './../../../components/communicate/report/DcycleReport';
import Snippet from './../../../components/communicate/snippet/Snippet';
import BudgetCalculator from './../../../components/freeTrials/budgetCalculator/BudgetCalculatorV2';
import HubspotCalendar from './../../../components/freeTrials/hubspotCalendar/HubspotCalendar';
import ImproveMainPage from './../../../components/improve/improveMainPage/ImproveMainPage';
import Offset from './../../../components/improve/offset/Offset';
import Recommendations from './../../../components/improve/recommendations/Recommendations';
import Invoicing from './../../../components/invoicing/Invoicing';
import AdminRoute from './../../../components/layout/AdminRoute/AdminRoute';
import PrivateRoute from './../../../components/layout/PrivateRoute/PrivateRoute';
import ErrorPageNotFound from './../../../components/layout/errorPage/ErrorPageNotFound/ErrorPageNotFound';
import ErrorPageSomethingWentWrong from './../../../components/layout/errorPage/ErrorPageSomethingWentWrong/ErrorPageSomethingWentWrong';
import ErrorPageSomethingWentWrongEmployeeForm from './../../../components/layout/errorPage/ErrorPageSomethingWentWrong/ErrorPageSomethingWentWrongEmployeeForm';
import MainLayout from './../../../components/layout/mainLayout/MainLayout';
import { LCA } from './../../../components/lca/LCA';
import BusinessTravels from './../../../components/measure/activity/businessTravels/BusinessTravels';
import BusinessTravelForm from './../../../components/measure/activity/businessTravels/businessTravelForm/businessTravelForm';
import Employees from './../../../components/measure/activity/employees/Employees';
import Purchases from './../../../components/measure/activity/purchases/Purchases';
import Shipments from './../../../components/measure/activity/shipments/Shipments';
import SocialAndGovernance from './../../../components/measure/activity/socialAndGovernance/SocialAndGovernance';
import Companies from './../../../components/measure/companies/Companies';
import Facilities from './../../../components/measure/facilities/Facilities';
import FundDetail from './../../../components/measure/funds/components/fundDetail/FundDetail';
import Logistics from './../../../components/measure/logistics/Logistics';
import MeasureMainPage from './../../../components/measure/measureMainPage/MeasureMainPage';
import VehicleDetail from './../../../components/measure/vehicles/VehicleDetail';
import Members from './../../../components/members/Members';
import Callback from './../../../components/onboarding/Callback';
import OnboardingUser from './../../../components/onboarding/OnboardingUser';
import Login from './../../../components/onboarding/login/Login';
import OrganizationSelector from './../../../components/onboarding/organizationSelector/OrganizationSelector';
import Register from './../../../components/onboarding/register/Register';
import RegisterInvited from './../../../components/onboarding/register/RegisterInvited';
import RegisterCompany from './../../../components/onboarding/register/registerCompany/RegisterCompany';
import Profile from './../../../components/profile/Profile';
import Tasks from './../../../components/tasks/Tasks';
import CalendlyMeeting from './../../../components/ui/calendlyMeeting/CalendlyMeeting';
import { BOOK_MEETING_URL, LCA_CALENDLY_URL } from './../../../constants';
import { ROUTES } from 'constants/routes';
import DataProgress from 'components/dataProgress/DataProgress';
import SignUp from 'components/onboarding/SignUp';
import FrameworkDashboard from 'components/dashboard/controlPanel/components/frameworkProgress/components/FrameworkDashboard/FrameworkDashboard';
import { LCAMaterialManagement } from 'components/lca/MaterialManagement/LCAMaterialManagement';

const Router = () => {
  const flags = useFeatureFlags();

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path={ROUTES.SIGNUP} element={<SignUp />} />
        <Route path={`${ROUTES.REGISTER}/:id`} element={<Register />} />
        <Route path={`${ROUTES.REGISTER_INVITED}/:id`} element={<RegisterInvited />} />
        <Route path={`${ROUTES.REGISTER_COMPANY}/:user_id`} element={<RegisterCompany />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.CALLBACK} element={<Callback />} />
        <Route path={`${ROUTES.ONBOARDING}/:id`} element={<OnboardingUser />} />
        <Route
          path={`${ROUTES.CREATE_ORGANIZATION_HOLDING}/:id/user/:userId`}
          element={<CreateOrganizationHolding />}
        />
        <Route path={`${ROUTES.NOT_FOUND}`} element={<ErrorPageNotFound />} />
        <Route path={`${ROUTES.SOMETHING_WENT_WRONG}`} element={<ErrorPageSomethingWentWrong />} />
        <Route
          path={`${ROUTES.EMPLOYEE_FORM}/:id/:startDate/:endDate`}
          element={<EmployeeFormController isQR={false} />}
        />
        <Route
          path={`${ROUTES.EMPLOYEE_FORM_QR}/:id/:lang/:startDate/:endDate`}
          element={
            flags?.qrEmployees ? <EmployeeFormController isQR={true} /> : <ErrorPageNotFound />
          }
        />
        <Route
          path={`${ROUTES.SOMETHING_WENT_WRONG}/:id`}
          element={<ErrorPageSomethingWentWrongEmployeeForm />}
        />
        <Route path={`${ROUTES.BUSINESS_TRAVEL_FORM}/:id`} element={<BusinessTravelForm />} />
        <Route
          path={ROUTES.ORGANIZATION_SELECTOR}
          element={
            <PrivateRoute allowedInFundRole>
              <OrganizationSelector />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE}
          element={
            <PrivateRoute>
              <MainLayout showFilterDates>
                <MeasureMainPage />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_COMPANIES}
          element={
            <PrivateRoute>
              <MainLayout>
                <Companies />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_COMPANIES + '/:id'}
          element={
            <PrivateRoute>
              <MainLayout>
                <CompanyDetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_FACILITIES}
          element={
            <PrivateRoute>
              <MainLayout showFilterDates>
                <Facilities />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.MEASURE_FACILITIES}/:id`}
          element={
            <PrivateRoute>
              <MainLayout>
                <FacilityDetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_ESG_METRICS_INFOGRAPHY}
          element={
            <PrivateRoute>
              <MainLayout>
                <EsgMetricsInfography />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE}
          element={
            <PrivateRoute>
              <MainLayout showFilterDates>
                <ImproveMainPage />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_DASHBOARD}
          element={
            <PrivateRoute allowedInFundRole>
              <MainLayout showFilterDates>
                <Layout>
                  <DashboardTotal />
                </Layout>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_DASHBOARD_TOTAL}
          element={
            <PrivateRoute allowedInFundRole>
              <MainLayout showFilterDates>
                <Layout>
                  <DashboardTotal />
                </Layout>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_DASHBOARD_INVESTMENT}
          element={
            <PrivateRoute allowedInFundRole>
              <MainLayout showFilterDates>
                <Layout>
                  <DashboardInvestment />
                </Layout>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_DASHBOARD_GROUPING}
          element={
            <PrivateRoute allowedInFundRole>
              <MainLayout showFilterDates>
                <Layout>
                  <DashboardGrouping />
                </Layout>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_DASHBOARD_LOGISTICS}
          element={
            <PrivateRoute allowedInFundRole>
              <MainLayout showFilterDates>
                <Layout>
                  <DashboardLogistic />
                </Layout>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_DASHBOARD_KPIS}
          element={
            <PrivateRoute allowedInFundRole>
              <MainLayout showFilterDates>
                <Layout>
                  <DashboardEsg />
                </Layout>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_DASHBOARD_CONTROL_PANEL}
          element={
            <PrivateRoute allowedInFundRole>
              <MainLayout showFilterDates>
                <Layout>
                  <ControlPanel />
                </Layout>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.IMPROVE_DASHBOARD_TOTAL}/:frameworkName`}
          element={
            <PrivateRoute allowedInFundRole>
              <MainLayout showFilterDates>
                <Layout>
                  <FrameworkDashboard />
                </Layout>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_RECOMMENDATIONS}
          element={
            <PrivateRoute>
              <MainLayout showFilterDates>
                <Recommendations />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_OFFSET}
          element={
            <PrivateRoute>
              <MainLayout showFilterDates>
                <Offset />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_MODELING}
          element={
            <PrivateRoute featureFlag>
              <MainLayout>
                <Modeling />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.IMPROVE_SBTI}
          element={
            <PrivateRoute>
              <MainLayout>
                <Sbti />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.IMPROVE_SBTI}/:id`}
          element={
            <PrivateRoute>
              <MainLayout>
                <Sbti />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.COMMUNICATE_14001}
          element={
            <PrivateRoute>
              <MainLayout>
                <></>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_VEHICLES}
          element={
            <PrivateRoute>
              <MainLayout>
                <Vehicles />
              </MainLayout>
            </PrivateRoute>
          }
        />
        {/* <Route
                path={ROUTES.MEASURE_VEHICLES_FREIGHT}
                element={
                  <PrivateRoute>
                    <MainLayout showFilterDates>
                      <FreightVehicles />
                    </MainLayout>
                  </PrivateRoute>
                }
              /> */}
        <Route
          path={`${ROUTES.MEASURE_VEHICLES}/:id`}
          element={
            <PrivateRoute>
              <MainLayout>
                <VehicleDetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_LOGISTICS}
          element={
            <PrivateRoute>
              <MainLayout>
                <Logistics />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.MEASURE_UPLOADED_FILES}/:category`}
          element={
            <PrivateRoute>
              <MainLayout>
                <UploadedFiles />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_UPLOADED_FILES}
          element={
            <PrivateRoute>
              <MainLayout>
                <UploadedFiles />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_ACTIVITY_EMPLOYEES}
          element={
            <PrivateRoute>
              <MainLayout>
                <Employees />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_ACTIVITY_BUSINESS_TRAVELS}
          element={
            <PrivateRoute>
              <MainLayout>
                <BusinessTravels />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_ACTIVITY_SHIPMENTS}
          element={
            <PrivateRoute>
              <MainLayout>
                <Shipments />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_ACTIVITY_PURCHASES}
          element={
            <PrivateRoute>
              <MainLayout>
                <Purchases />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.PROFILE}
          element={
            <PrivateRoute>
              <MainLayout>
                <Profile />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEMBERS}
          element={
            <PrivateRoute>
              <MainLayout>
                <Members />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.INVOICING}
          element={
            <PrivateRoute>
              <MainLayout>
                <Invoicing />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.TODOS}
          element={
            <PrivateRoute>
              <MainLayout>
                <Tasks />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.TODOS + '/:id'}
          element={
            <PrivateRoute>
              <MainLayout>
                <Tasks />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route path={ROUTES.COMMUNICATE_WEB} element={<DcycleReport />} />
        <Route
          path={ROUTES.SNIPPET}
          element={
            <PrivateRoute>
              <MainLayout>
                <Snippet />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_ORGANIZATIONS}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <OrganizationList />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.ADMIN_ORGANIZATIONS}/:id`}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <OrganizationDetail />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_USERS}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <UsersList />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_SNIPPET_UPLOAD}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <SnippetUpload />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_PURCHASES}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <PurchasesUpload />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_PURCHASES_CODE_MANAGEMENT}
          element={
            <PrivateRoute featureFlag={flags?.codeManagement}>
              <MainLayout>
                <AdminRoute>
                  <CodeManagement />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_EMAILS}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <Emails />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_SHIPMENTS}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <ShipmentsUpload />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_BUSINESS_TRAVEL}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <BusinessTravelUpload />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_VEHICLE_CONSUMPTIONS}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <VehicleConsumptionUpload />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_LCA}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <LCAAdmin />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_TASKS}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <TasksList />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.ADMIN_LCA}/v2`}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <LCAAdminV2 />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_LCA_SCENARIOS}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <LCAAdminScenarios />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ADMIN_INVOFOX}
          element={
            <PrivateRoute>
              <MainLayout>
                <AdminRoute>
                  <Invofox />
                </AdminRoute>
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.COMMUNICATE_GHG}`}
          element={
            <PrivateRoute>
              <GHGReport />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.BUDGET_CALCULATOR}
          element={
            <PrivateRoute>
              <MainLayout>
                <BudgetCalculator />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.BOOK_MEETING}
          element={
            <PrivateRoute>
              <MainLayout>
                <HubspotCalendar url={BOOK_MEETING_URL} />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.CUSTOMIZE_MAIN_PAGE}
          element={
            <PrivateRoute>
              <MainLayout>
                <CustomizeMainPage />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.CUSTOM_EMISSION_FACTORS}
          element={
            <PrivateRoute>
              <MainLayout>
                <CustomEF />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.CUSTOM_METRICS}
          element={
            <PrivateRoute featureFlag={flags?.showMetrics}>
              <MainLayout>
                <CustomMetrics />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.CUSTOM_EMISSION_FACTORS}/:id`}
          element={
            <PrivateRoute>
              <MainLayout>
                <CustomEFDetails />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.CUSTOM_INTENSITY_METRICS}
          element={
            <PrivateRoute featureFlag={flags?.intensityMetrics}>
              <MainLayout>
                <IntensityMetricsGroups />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.CUSTOM_INTENSITY_METRICS}/:id`}
          element={
            <PrivateRoute featureFlag={flags?.intensityMetrics}>
              <MainLayout>
                <IntensityMetricsDetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.CUSTOM_GROUPING}
          element={
            <PrivateRoute featureFlag={flags?.grouping}>
              <MainLayout>
                <Grouping />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.CUSTOM_GROUPING}/:id`}
          element={
            <PrivateRoute featureFlag={flags?.grouping}>
              <MainLayout>
                <GroupingDetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.LCA}
          element={
            <PrivateRoute>
              <MainLayout>
                <LCA />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.LCA_PORTFOLIO}
          element={
            <PrivateRoute>
              <MainLayout>
                <LCAPortfolio />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.LCA_PORTFOLIO}/:id`}
          element={
            <PrivateRoute>
              <MainLayout>
                <LCADetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.LCA_MATERIAL_MANAGEMENT}
          element={
            <PrivateRoute>
              <MainLayout>
                <LCAMaterialManagement />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_ACTIVITY_SOCIAL_AND_GOVERNANCE}
          element={
            <PrivateRoute featureFlag={flags?.socialAndGovernance} allowedInFundRole>
              <MainLayout>
                <SocialAndGovernance />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.LCA_CALENDLY_MEETING}
          element={
            <PrivateRoute>
              <MainLayout>
                <CalendlyMeeting url={LCA_CALENDLY_URL} />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_FUNDS}
          element={
            <PrivateRoute featureFlag={flags?.funds} companyTypes={['group_fund']}>
              <MainLayout>
                <Funds />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.MEASURE_FUNDS}/:id`}
          element={
            <PrivateRoute featureFlag={flags?.funds} companyTypes={['group_fund']}>
              <MainLayout>
                <FundDetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_EVENTS}
          element={
            <PrivateRoute featureFlag={flags?.events}>
              <MainLayout>
                <Events />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.MEASURE_EVENTS}/:id`}
          element={
            <PrivateRoute featureFlag={flags?.events}>
              <MainLayout>
                <EventDetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.IMPROVE_AVOIDED_EMISSIONS}`}
          element={
            <PrivateRoute featureFlag={flags?.avoidedEmissions}>
              <MainLayout>
                <AvoidedEmissions />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_DATADIS}
          element={
            <PrivateRoute featureFlag={flags?.datadis}>
              <MainLayout>
                <Datadis />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.MEASURE_DATADIS}/:nif`}
          element={
            <PrivateRoute featureFlag={flags?.datadis}>
              <MainLayout>
                <DatadisContracts />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_HUBS}
          element={
            <PrivateRoute featureFlag={flags?.hubs}>
              <MainLayout>
                <Hubs />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.MEASURE_HUBS}/:id`}
          element={
            <PrivateRoute featureFlag={flags?.hubs}>
              <MainLayout>
                <FacilityDetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.COMMUNICATE_GLEC}
          element={
            <PrivateRoute>
              <GlecReport />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_ACTIVITY_USE_AND_END_OF_LIFE}
          element={
            <PrivateRoute>
              <MainLayout>
                <UseAndEndOfLife />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_ESG_METRICS}
          element={
            <PrivateRoute>
              <MainLayout showFilterDates>
                <EsgMetrics />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MEASURE_ESG_METRICS + '/:category'}
          element={
            <PrivateRoute>
              <MainLayout showFilterDates>
                <ESGMetricsDetail />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.COMMUNICATE_MITECO}
          element={
            <PrivateRoute>
              <MainLayout>
                <MITECO />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DATA_PROGRESS}
          element={
            <PrivateRoute>
              <MainLayout>
                <DataProgress />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route path='*' element={<ErrorPageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
