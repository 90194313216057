import { LCACard } from 'components/lca/Portfolio/LCADetail/components/LCACard';
import { limitString } from 'utils/limitString';
import uuid from 'uuid';

type Props = {
  title: string;
  tags: string[];
  disabled?: boolean;
};

export const MaterialCard: React.FC<Props> = ({ title, tags, disabled }) => {
  return (
    <LCACard.Layout>
      <LCACard.Content>
        <LCACard.Icon icon='leather' lookAndFeel='material' disabled={disabled} />
        <div className='flex-col gap-y-2'>
          <h4 className='font-body-b1-b m-0'>{title}</h4>
          <div className='flex gap-x-1'>
            {tags.map((tag) => (
              <LCACard.Tag title={tag} key={uuid.v4()}>
                {limitString(tag, 16)}
              </LCACard.Tag>
            ))}
          </div>
        </div>
      </LCACard.Content>
    </LCACard.Layout>
  );
};
