import SectionHeader from 'components/layout/sectionHeader/SectionHeader';
import { MaterialCard } from './components/MaterialCard';
import Button from 'components/ui/button/Button';
import CardWrapper from 'components/ui/cards/common/CardWrapper';
import { Filters } from 'components/layout/NewFilters/Filters';
import { useState } from 'react';
import { IFilter } from 'utils/url';
import { FilterText } from 'components/layout/NewFilters/FilterText';
import Icon from 'components/ui/icon/Icon';
import FilterHandlers from 'components/layout/NewFilters/FiltersHandlers';
import TotalLegendV2 from 'components/ui/totalLegend/TotalLegendV2';
import { useLCAMaterials } from '../hooks/useLCAMaterials';
import { makeTags } from './utils';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import InfiniteScroll from 'react-infinite-scroll-component';

export const LCAMaterialManagement = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const { t: tagT } = useLCATranslation({ keyPrefix: 'materialManagement.tags' });

  const { data, fetchNextPage, hasNextPage } = useLCAMaterials({
    filters,
    size: 20
  });

  return (
    <div className='flex-col gap-y-6 h-95svh'>
      <SectionHeader title='Maestro de Materiales (Materias Primas y Energías)' />
      <CardWrapper
        style={{
          padding: '1rem 1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          flexGrow: 1,
          height: '100%'
        }}>
        <Filters.Root filters={filters} setFilters={setFilters} open={isOpen} setOpen={setIsOpen}>
          <Filters.Menu />
          <div className='flex justify-between items-center'>
            <div className='flex gap-x-4'>
              <FilterText field='name' type='il' placeholder='Busca por Materiales' />
              <FilterHandlers blacklistedFilters={{ all: ['name'] }} />
            </div>
            <div className='bg-neutral-gray-80 p-2 flex gap-x-2 rounded-4'>
              <Button
                text='Descarga los Materiales'
                lookAndFeel='secondary'
                size='small'
                iconNode={<Icon icon='download' color='gray-dark' />}
              />
              <TotalLegendV2
                totalElements={[{ value: 32, label: 'Materiales' }]}
                totalLabel='Total'
              />
            </div>
          </div>

          <div className='flex-grow h-full overflow-y-auto' id='infinite-scroll-container'>
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              dataLength={data?.length ?? 0}
              loader={<h4>Loading...</h4>}
              scrollableTarget='infinite-scroll-container'>
              <div className='grid-cols-3 gap-4'>
                <div className='bg-neutral-gray-80 h-full border-box rounded-8 grid-cols-1'>
                  <div className='flex gap-x-2 justify-center items-center'>
                    <Button
                      lookAndFeel='primary'
                      iconNode={<Icon icon='upload' color='white' />}
                      text='Sube tus Materiales'
                      size='small'
                      style={{ width: 'fit-content' }}
                    />
                    <Button
                      lookAndFeel='secondary'
                      iconNode={<Icon icon='add' color='gray-dark' />}
                      text='Añade manualmente'
                      size='small'
                      style={{ width: 'fit-content' }}
                    />
                  </div>
                </div>
                {data?.map((material) => (
                  <MaterialCard
                    key={material.id}
                    title={material.name}
                    tags={makeTags(material, tagT)}
                  />
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </Filters.Root>
      </CardWrapper>
    </div>
  );
};
