import { TFunction } from 'react-i18next';
import { LCAMaterial } from 'services/api/lca/lcaPortfolio';

export const makeTags = (material: LCAMaterial, t: TFunction) => {
  const tags: string[] = [];

  if (material.country) {
    tags.push(t('country', { country: material.country }));
  }

  if (material.supplier) {
    tags.push(material.supplier);
  }

  return tags;
};
