import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ADMIN_WHITE_LIST } from '../../../../constants';
import { ROUTES } from 'constants/routes';
import { ESGModeContext } from '../../../../context/esgModeContext';
import { UserContext } from '../../../../context/userContext';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import PremiumFeatureAdvise from '../../../freeTrials/premiumFeatureAdvise/PremiumFeatureAdvise';
import { CSRDModal } from '../../../freeTrials/premiumFeatureModal/modalInstances/CSRDModal';
import { Iso14001Modal } from '../../../freeTrials/premiumFeatureModal/modalInstances/Iso14001Modal';
import { LeanAndGreenModal } from '../../../freeTrials/premiumFeatureModal/modalInstances/LeanAndGreenModal';
import Icon from '../../../ui/icon/Icon';
import { CDPModal } from '../../../freeTrials/premiumFeatureModal/modalInstances/CDPModal';
import { EcoVadisModal } from '../../../freeTrials/premiumFeatureModal/modalInstances/EcoVadisModal';

type Props = {
  flags?: Flag;
};
// ##############################
// The ID of each section should be the id of the feature flag created if it exists
// ##############################
const useSections = ({ flags }: Props) => {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const { mode } = useContext(ESGModeContext);

  // SHOW ADMIN
  // Determine if user is an admin based on email whitelist
  let showAdmin = ADMIN_WHITE_LIST.some((email) => user?.email.includes(email));

  // If in production environment, also check if user belongs to main organization
  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    const isMainOrgMember = !!user?.organizations?.find(
      (org) => org.id === process.env.REACT_APP_DCYCLE_MAIN_ID
    );
    showAdmin = showAdmin && isMainOrgMember; // Update showAdmin based on both conditions
  }

  // SHOW COMPANIES
  const selectedOrganization = useSelectedOrganization();

  let showCompanies = true;

  if (selectedOrganization?.is_group_fund && !flags?.funds) {
    showCompanies = false;
  }

  if (selectedOrganization?.role === 'member') {
    showCompanies = false;
  }

  // SHOW FUNDS
  const showFunds = showCompanies && !!selectedOrganization?.is_group_fund;

  // Check if role is fund and only return the sections that are allowed for funds
  if (selectedOrganization?.role === 'fund') {
    const sections: Section[] = [
      {
        id: 'dashboard',
        icon: '/images/icons/bars.svg',
        iconSelected: '/images/icons/barsWhite.svg',
        text: t('sideMenu.dashboard'),
        route: ROUTES.IMPROVE_DASHBOARD,
        subSections:
          mode === 'environmental'
            ? [
                {
                  id: 'total',
                  text: t('dashboard.consolidated'),
                  route: ROUTES.IMPROVE_DASHBOARD,
                  newTab: false,
                  hide: false
                },
                {
                  id: 'investment',
                  text: t('dashboard.investment'),
                  route: ROUTES.IMPROVE_DASHBOARD_INVESTMENT,
                  newTab: false,
                  hide: false
                },
                {
                  id: 'grouping',
                  text: t('dashboard.grouping'),
                  route: ROUTES.IMPROVE_DASHBOARD_GROUPING,
                  newTab: false,
                  hide: false
                },
                {
                  id: 'logistics',
                  text: t('dashboard.logistics'),
                  route: ROUTES.IMPROVE_DASHBOARD_LOGISTICS,
                  newTab: false,
                  hide:
                    selectedOrganization?.sector !== 'transport' ||
                    selectedOrganization?.id !== 'cefc7985-41f5-42d1-8d49-536c1ab0977f'
                },
                {
                  id: 'datapoints',
                  text: t('dashboard.datapoints'),
                  route: ROUTES.IMPROVE_DASHBOARD_KPIS,
                  newTab: false,
                  hide: true
                }
              ]
            : [],
        isNew: false
      },
      {
        id: 'measure',
        icon: '/images/icons/papersGradient.svg',
        iconSelected: '/images/icons/papers.svg',
        text: t('sideMenu.measure'),
        route: null,
        isNew: false,
        subSections: [
          {
            id: 'activity',
            text: t('sideMenu.activity'),
            route: null,
            subSections: [
              {
                id: 'socialAndGovernance',
                text: t('sideMenu.socialAndGovernance'),
                route: ROUTES.MEASURE_ACTIVITY_SOCIAL_AND_GOVERNANCE
              }
            ]
          }
        ]
      }
    ];
    return {
      sections,
      sectionsBottom: []
    };
  }
  const sections: Section[] = [
    {
      id: 'dashboard',
      icon: '/images/icons/bars.svg',
      iconSelected: '/images/icons/barsWhite.svg',
      text: t('sideMenu.dashboard'),
      route: ROUTES.IMPROVE_DASHBOARD,
      subSections:
        mode === 'environmental'
          ? [
              {
                id: 'total',
                text: t('dashboard.consolidated'),
                route: ROUTES.IMPROVE_DASHBOARD,
                newTab: false,
                hide: false
              },
              {
                id: 'investment',
                text: t('dashboard.investment'),
                route: ROUTES.IMPROVE_DASHBOARD_INVESTMENT,
                newTab: false,
                hide: false
              },
              {
                id: 'grouping',
                text: t('dashboard.grouping'),
                route: ROUTES.IMPROVE_DASHBOARD_GROUPING,
                newTab: false,
                hide: false
              },
              {
                id: 'logistics',
                text: t('dashboard.logistics'),
                route: ROUTES.IMPROVE_DASHBOARD_LOGISTICS,
                newTab: false,
                hide:
                  selectedOrganization?.sector !== 'transport' ||
                  selectedOrganization?.id !== 'cefc7985-41f5-42d1-8d49-536c1ab0977f'
              },
              {
                id: 'kpis',
                text: 'KPIs Growth',
                route: ROUTES.IMPROVE_DASHBOARD_KPIS,
                newTab: false,
                hide: false
              }
            ]
          : [],
      isNew: false
    },
    {
      id: 'measure',
      icon: '/images/icons/papersGradient.svg',
      iconSelected: '/images/icons/papers.svg',
      text: t('sideMenu.measure'),
      route: ROUTES.MEASURE,
      isNew: false,
      subSections: [
        {
          id: 'companies',
          text: t('sideMenu.companies'),
          route: ROUTES.MEASURE_COMPANIES,
          newTab: false,
          hide: !showCompanies
        },
        {
          id: 'funds',
          text: t('sideMenu.funds'),
          route: ROUTES.MEASURE_FUNDS,
          newTab: false,
          hide: !showFunds
        },
        {
          id: 'facilities',
          text: t('sideMenu.facilities'),
          route: ROUTES.MEASURE_FACILITIES,
          subSections: [
            {
              id: 'datadis',
              text: t('sideMenu.datadis'),
              route: ROUTES.MEASURE_DATADIS
            },
            {
              id: 'hubs',
              text: t('sideMenu.hubs'),
              route: ROUTES.MEASURE_HUBS,
              hide: selectedOrganization?.sector !== 'transport'
            }
          ]
        },
        {
          id: 'vehicles',
          text: t('sideMenu.vehicles'),
          route: ROUTES.MEASURE_VEHICLES,
          subSections: []
        },
        {
          id: 'logistics',
          text: t('sideMenu.logistics'),
          route: ROUTES.MEASURE_LOGISTICS,
          hide: selectedOrganization?.sector !== 'transport',
          subSections: []
        },
        {
          id: 'esg_Metrics',
          text: t('sideMenu.esgMetrics'),
          route: ROUTES.MEASURE_ESG_METRICS,
          subSections: [],
          isNew: false
        },
        {
          id: 'events',
          text: t('sideMenu.events'),
          route: ROUTES.MEASURE_EVENTS,
          subSections: []
        },
        {
          id: 'activity',
          text: t('sideMenu.activity'),
          subSections: [
            {
              id: 'employees',
              text: t('sideMenu.employees'),
              route: ROUTES.MEASURE_ACTIVITY_EMPLOYEES
            },
            {
              id: 'travels',
              text: t('sideMenu.travels'),
              route: ROUTES.MEASURE_ACTIVITY_BUSINESS_TRAVELS
            },
            {
              id: 'shipments',
              text: t('sideMenu.shipments'),
              route: ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=downstream'
            },
            {
              id: 'deliveries',
              text: t('sideMenu.deliveries'),
              route: ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=upstream'
            },
            {
              id: 'purchases',
              text: t('sideMenu.purchases'),
              route: ROUTES.MEASURE_ACTIVITY_PURCHASES
            },
            {
              id: 'useAndEndOfLife',
              text: t('sideMenu.useAndEndOfLife'),
              route: ROUTES.MEASURE_ACTIVITY_USE_AND_END_OF_LIFE
            },
            {
              id: 'socialAndGovernance',
              text: t('sideMenu.socialAndGovernance'),
              route: ROUTES.MEASURE_ACTIVITY_SOCIAL_AND_GOVERNANCE
            }
          ],
          route: null
        },
        {
          id: 'uploadedFiles',
          text: t('sideMenu.uploadedFiles'),
          icon: <Icon icon='files_documents' color='gradient' />,
          route: ROUTES.MEASURE_UPLOADED_FILES,
          subSections: []
        }
      ]
    },
    {
      id: 'improve',
      icon: '/images/icons/settingsGradient.svg',
      iconSelected: '/images/icons/settings.svg',
      text: t('sideMenu.improve'),
      route: ROUTES.IMPROVE,
      isNew: false,
      subSections: [
        {
          id: 'compensations',
          text: t('sideMenu.compensations'),
          route: ROUTES.IMPROVE_OFFSET
        },
        {
          id: 'recommendations',
          text: t('sideMenu.recommendations'),
          route: ROUTES.IMPROVE_RECOMMENDATIONS
        },
        {
          id: 'avoidedEmissions',
          text: t('sideMenu.avoidedEmissions'),
          route: ROUTES.IMPROVE_AVOIDED_EMISSIONS
        },
        {
          id: 'modeling',
          text: t('sideMenu.modeling'),
          route: ROUTES.IMPROVE_MODELING
        },
        {
          id: 'sbtiCriteria',
          text: t('sideMenu.sbtiCriteria'),
          route: ROUTES.IMPROVE_SBTI,
          isNew: true
        },
        {
          id: 'leanAndGreen',
          text: t('sideMenu.leanAndGreen'),
          route: ROUTES.IMPROVE_LEAN_AND_GREEN,
          isNew: true,
          paidRouteModal:
            selectedOrganization?.subscription_plan === 'free_plan' ? (
              <PremiumFeatureAdvise />
            ) : (
              <LeanAndGreenModal />
            )
        }
      ]
    },
    {
      id: 'communicate',
      icon: '/images/icons/leaf.svg',
      iconSelected: '/images/icons/leafWhite.svg',
      text: t('sideMenu.communicate'),
      route: null,
      isNew: false,
      subSections: [
        {
          id: 'snippet',
          text: t('sideMenu.snippet'),
          newTab: false,
          route: '?showOrgDescriptionModalSnippet=true'
        },
        {
          id: 'web',
          text: t('sideMenu.web'),
          route: `?showOrgDescriptionModalDcycle=true`,
          newTab: false
        },
        {
          id: 'mitecoExcel',
          text: t('sideMenu.MITECO'),
          route: ROUTES.COMMUNICATE_MITECO
        },
        {
          id: 'ghg',
          text: t('sideMenu.ghg'),
          route: `?showOrgDescriptionModalGHG=true`,
          newTab: false
        },
        {
          id: 'pia',
          text: t('sideMenu.pai'),
          route: '?showOrgDescriptionModalPai=true',
          newTab: false,
          hide: !showFunds
        },
        {
          id: 'iso14064',
          text: t('sideMenu.iso14064'),
          route: '?showOrgDescriptionModalIso14064=true',
          newTab: false
        },
        {
          id: 'iso_14001',
          text: t('sideMenu.iso14001'),
          route: ROUTES.COMMUNICATE_14001,
          isNew: false,
          paidRouteModal:
            selectedOrganization?.subscription_plan === 'free_plan' ? (
              <PremiumFeatureAdvise />
            ) : (
              <Iso14001Modal />
            )
        },
        {
          id: 'csrd_reporting',
          text: t('sideMenu.csrdReporting'),
          route: ROUTES.COMMUNICATE_CSRD_REPORTING,
          isNew: false,
          paidRouteModal:
            selectedOrganization?.subscription_plan === 'free_plan' ? (
              <PremiumFeatureAdvise />
            ) : (
              <CSRDModal />
            )
        },
        {
          id: 'ecovadis_reporting',
          text: t('sideMenu.ecovadisReporting'),
          route: ROUTES.COMMUNICATE_ECOVADIS_REPORTING,
          isNew: false,
          paidRouteModal:
            selectedOrganization?.subscription_plan === 'free_plan' ? (
              <PremiumFeatureAdvise />
            ) : (
              <EcoVadisModal />
            )
        },
        {
          id: 'cdp_reporting',
          text: t('sideMenu.cdpReporting'),
          route: ROUTES.COMMUNICATE_CDP_REPORTING,
          isNew: false,
          paidRouteModal:
            selectedOrganization?.subscription_plan === 'free_plan' ? (
              <PremiumFeatureAdvise />
            ) : (
              <CDPModal />
            )
        }
      ]
    }
  ];

  const sectionsBottom: Section[] = [
    {
      id: 'customize',
      icon: '/images/icons/arrowGoingUp.svg',
      iconSelected: '/images/icons/arrowGoingUpWhite.svg',
      text: t('sideMenu.customize'),
      route: ROUTES.CUSTOMIZE_MAIN_PAGE,
      isNew: false,
      subSections: [
        {
          id: 'emission-factors',
          text: t('sideMenu.emissionFactors'),
          route: ROUTES.CUSTOM_EMISSION_FACTORS
        },
        {
          id: 'intensityMetrics',
          text: t('sideMenu.intensityMetrics'),
          route: ROUTES.CUSTOM_INTENSITY_METRICS
        },
        {
          id: 'grouping',
          text: t('sideMenu.grouping'),
          route: ROUTES.CUSTOM_GROUPING
        }
      ]
    },
    {
      id: 'lca',
      icon: '/images/icons/tshirt.svg',
      iconSelected: '/images/icons/tshirtWhite.svg',
      text: t('sideMenu.lca'),
      route: ROUTES.LCA_PORTFOLIO,
      isNew: false,
      subSections: [
        {
          id: 'lcaPortfolio',
          text: t('sideMenu.lcaPortfolio'),
          route: ROUTES.LCA_PORTFOLIO
        },
        {
          id: 'lcaMaterialManagement',
          text: t('sideMenu.lcaMaterialManagement'),
          route: ROUTES.LCA_MATERIAL_MANAGEMENT
        }
      ]
    },
    {
      id: 'admin',
      icon: '/images/icons/settingsGradient.svg',
      iconSelected: '/images/icons/settings.svg',
      text: t('sideMenu.admin'),
      route: null,
      isNew: false,
      hide: !showAdmin,
      subSections: [
        {
          id: 'organizations',
          text: t('sideMenu.organizations'),
          route: ROUTES.ADMIN_ORGANIZATIONS
        },
        {
          id: 'users',
          text: t('sideMenu.users'),
          route: ROUTES.ADMIN_USERS,
          hide: process.env.REACT_APP_PARTNER !== 'dcycle'
        },
        {
          id: 'snippet',
          text: t('sideMenu.snippet'),
          route: ROUTES.ADMIN_SNIPPET_UPLOAD
        },
        {
          id: 'purchasesUpload',
          text: t('sideMenu.purchases'),
          route: ROUTES.ADMIN_PURCHASES,
          subSections: [
            {
              id: 'codeManagement',
              text: t('sideMenu.codeManagement'),
              route: ROUTES.ADMIN_PURCHASES_CODE_MANAGEMENT
            }
          ]
        },
        {
          id: 'shipmentsUpload',
          text: t('sideMenu.goods'),
          route: ROUTES.ADMIN_SHIPMENTS
        },
        {
          id: 'emails',
          text: t('sideMenu.sendEmails'),
          route: ROUTES.ADMIN_EMAILS
        },
        {
          id: 'businessTravelUpload',
          text: t('sideMenu.travels'),
          route: ROUTES.ADMIN_BUSINESS_TRAVEL
        },
        {
          id: 'vehicleConsumptionUpload',
          text: t('sideMenu.vehicleConsumptions'),
          route: ROUTES.ADMIN_VEHICLE_CONSUMPTIONS
        },
        {
          id: 'lcaAdmin',
          text: t('sideMenu.lca'),
          route: ROUTES.ADMIN_LCA
        },
        {
          id: 'lcaAdminV2',
          text: t('sideMenu.lcaV2'),
          route: `${ROUTES.ADMIN_LCA}/v2`
        },
        {
          id: 'lcaScenariosAdmin',
          text: t('sideMenu.lcaScenarios'),
          route: ROUTES.ADMIN_LCA_SCENARIOS
        },
        {
          id: 'tasks',
          text: t('sideMenu.tasks'),
          route: ROUTES.ADMIN_TASKS
        },
        {
          id: 'invofox',
          text: t('sideMenu.invofox'),
          route: ROUTES.ADMIN_INVOFOX
        }
      ]
    }
  ];

  if (flags?.showMetrics) {
    sectionsBottom.map((section) => {
      if (section.id === 'customize') {
        section.subSections.push({
          id: 'customMetrics',
          text: t('sideMenu.customMetrics'),
          route: ROUTES.CUSTOM_METRICS
        });
      }
    });
  }

  return { sections, sectionsBottom };
};

export default useSections;
